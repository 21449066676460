import icon1 from '../image/kcell-5G.png'
import icon2 from '../image/kcell-bc.png'
import icon3 from '../image/kar-tel-bc-new.png'
import icon5 from '../image/wi-fi.png'
import iconRed from '../image/amc-red.png'
import iconYellow from '../image/amc-yellow.png'
import iconGreen from '../image/amc-green.png'
import iconRedBee from '../image/amc-red-bee.png'
import iconYellowBee from '../image/amc-yellow-bee.png'
import iconGreenBee from '../image/amc-green-bee.png'
import odn from '../image/odn.png'
import cabisko from '../image/cabisko.png'
import aqniet from '../image/aqniet.png'
import oram276 from '../image/oram276.png'
import sayram from '../image/sayram.png'
import qainarbulak from '../image/qainarbulak.png'
import asar from '../image/asar.png'
import bazarqaqpa from '../image/bazarqaqpa.png'
import abdullabad from '../image/abdullabad.png'
import bozaryq from '../image/bozaryq.png'
import turan from '../image/turan.png'
import turan2 from '../image/turan2.png'
import asarkoilyk from '../image/asarkoilyk.png'
import shapagat from '../image/shapagat.png'
import tauelsizdik from '../image/tauelsizdik.png'
import OdnDostyq from '../image/OdnDostyq.png'
import OdnAsar from '../image/OdnAsar.png'
import OdnBozaryq from '../image/OdnBozaryq.png'
import OdnQainarbulaq from '../image/OdnQainarbulaq.png'
import OdnZhanatalap from '../image/OdnZhanatalap.png'
import saule from '../image/saule.png'
import kursay from '../image/kursay.png'
import kursayagro from '../image/kursayagro.png'
import kainarbulak from '../image/kainarbulak.png'
import kainarbulaks from '../image/kainarbulaks.png'
import kainarbulakss from '../image/kainarbulakss.png'

export const operators = [

    // Kcell 5G **************************************************************************************************************************
    {
      id: 1,
      name: 'Kcell 5G',
      points: [
        { id: 1, name: 'Point 13', coordinates: [42.339298, 69.639777] }, //г. Шымкент. ул.Рыскулова 36. торговый дом Север
        { id: 2, name: 'Point 13', coordinates: [42.310107, 69.608592] }, //г. Шымкент. ул.Толе би д.11
        { id: 3, name: 'Point 13', coordinates: [42.330457, 69.578829] },//г. Шымкент. мкр-он 17. районная котельная №1
        { id: 4, name: 'Point 13', coordinates: [42.333739, 69.603714] },//г. Шымкент. пр.Динмухаммед Кунаева д.59
        { id: 5, name: 'Point 13', coordinates: [42.315881, 69.586423] },//г. Шымкент. пр.Аль-Фараби б/н. Областной казахский музыкальный драматический театр
        { id: 6, name: 'Point 13', coordinates: [42.306004, 69.576263] },//г. Шымкент. пр.Республики 43. ОАО Шымкент Турист
        { id: 7, name: 'Point 13', coordinates: [42.298854, 69.609912] },//г. Шымкент. ул.Анарова б/н. ж/д вокзал. ТОО Темир Жолы-Кызмет
        { id: 8, name: 'Point 13', coordinates: [42.359024, 69.641693] },//г. Шымкент. мкр-он Нурсат. пр. Астана. новый театр
        { id: 9, name: 'Point 13', coordinates: [42.325149, 69.596287] },//г. Шымкент. ул. Байтурсынова уг. ул. Желтоксан гостиничный комплекс Риксос Хадиша
        { id: 10, name: 'Point 13', coordinates: [42.324878, 69.596022] },//Шымкент, ​проспект Кунаева, 5
        { id: 11, name: 'Point 13', coordinates: [42.305533, 69.624922] },//	г. Шымкент. ул.Жолдаса Сулейменова. «Центр доктора Орынбаева»
        { id: 12, name: 'Point 13', coordinates: [42.360062, 69.636019] },//г. Шымкент. мкр-он Нурсат. Байтерекова. Район 3 поликлиники
        { id: 284, name: 'Point 13', coordinates: [42.319834, 69.594878] },//г. Шымкент. ул.Тыныбаева 4. Район Мега, Гарант
        { id: 285, name: 'Point 13', coordinates: [42.345862, 69.611529] },//г. Шымкент. Вышка Отрар, Район гостинцы Рамада
        { id: 286, name: 'Point 13', coordinates: [42.335094, 69.592783] },//г. Шымкент. Центральный стадион
        { id: 287, name: 'Point 13', coordinates: [42.356039, 69.593872] },//г .Шымкент. ул.Малхарова б/н. 
        { id: 288, name: 'Point 13', coordinates: [42.313868, 69.568292] },//г. Шымкент. ул.Калдаякова 32a. пресс автомат
        { id: 289, name: 'Point 13', coordinates: [42.382004, 69.647858] },//г. Шымкент. мкр-он. Кайтпас-1
        { id: 290, name: 'Point 13', coordinates: [42.531564, 69.757769] },//Сайрамский район. с. Жанаталап. ул.Жамбыла 
        { id: 291, name: 'Point 13', coordinates: [42.323935, 69.563086] },//г. Шымкент. ул.Тверская 4. Шымкентский социально-педагогический университет
        { id: 292, name: 'Point 13', coordinates: [42.353867, 69.614060] },//г. Шымкент. проспект Байдибек-би. ТРЦ “Nauryz Park”
        { id: 293, name: 'Point 13', coordinates: [42.277805, 69.558587] },//г. Шымкент. Ташкентская трасса. Вышка. Мкр Сауле
        { id: 294, name: 'Point 13', coordinates: [42.318038, 69.579660] },//г. Шымкент. ул.Аскарова 23., ТЦ Югсити
        { id: 295, name: 'Point 13', coordinates: [42.340490, 69.522946] },//г. Шымкент. мкр-он Коммунизм
        { id: 296, name: 'Point 13', coordinates: [42.300634, 69.578385] },//г. Шымкент. ул.Рашидова б/н. АО Химфарм
        { id: 297, name: 'Point 13', coordinates: [42.335729, 69.559988] },//г. Шымкент. ул.Тамерлановкое шоссе б/н. рынок автозапчастей Тулпар 2030
        { id: 298, name: 'Point 13', coordinates: [42.327192, 69.544223] },//	г. Шымкент. ул.Гагарина б/н. Водонопорка
        { id: 299, name: 'Point 13', coordinates: [42.325785, 69.576643] },//г. Шымкент. ул.Мангельдина уг.ул.Тамерлановское шоссе
        { id: 300, name: 'Point 13', coordinates: [42.296316, 69.572667] },//г. Шымкент. мкрн-4 Агропром. здание 759мкрн-4 Агропром. здание 759. Онтустик жарык транзит
        { id: 301, name: 'Point 13', coordinates: [42.370474, 69.541584] },//г. Шымкент, улица Сагынбаева
        { id: 302, name: 'Point 13', coordinates: [42.312952, 69.620308] },//г. Шымкент. остановка Тауке Хана
        { id: 303, name: 'Point 13', coordinates: [42.332464, 69.542962] },//г. Шымкент. мкр-он Коргазын, ул.Мельдикожаева
        { id: 304, name: 'Point 13', coordinates: [42.349807, 69.534722] },//г. Шымкент. район Бекжан базара, ул.Тамерлановское шоссе б/н, угол ул. Сыпырган ата
        { id: 305, name: 'Point 13', coordinates: [42.316087, 69.600477] },//г. Шымкент. район Центрального парка, ул.Туркестанская
        { id: 306, name: 'Point 13', coordinates: [42.356165, 69.649534] },//г. Шымкент. мкр-он Нурсат. ЖК Астана, район новый мечеть
        { id: 307, name: 'Point 13', coordinates: [42.316346, 69.565041] },//г. Шымкент. мкр-он Хамза, ул.Калдаякова 
        { id: 308, name: 'Point 13', coordinates: [42.368287, 69.491033] },//г. Шымкент. мкр-он Кызылжар. ул.Аэропортская 
        { id: 309, name: 'Point 13', coordinates: [42.335955, 69.620355] },//г. Шымкент. мкр МЖК ул.Джандосова. ТОО Адал
        { id: 310, name: 'Point 13', coordinates: [42.364291, 69.734105] },//г. Шымкент. район Таскен. вышка Kcell
        { id: 311, name: 'Point 13', coordinates: [42.320471, 69.586448] },//г. Шымкент. район Гульжан остановка, проспект Республики
        { id: 312, name: 'Point 13', coordinates: [42.332464, 69.542962] },//г. Шымкент. отделение Тельман. ул.Спатаева. Крестьянское хозяйство
        { id: 312, name: 'Point 13', coordinates: [42.291963, 69.597159] },//г. Шымкент. ул.Моторная 1. ОАО Шымкент май
        { id: 313, name: 'Point 13', coordinates: [42.347898, 69.704330] },//г. Шымкент. ул.Ауезова
        { id: 314, name: 'Point 13', coordinates: [42.305019, 69.639916] },//г. Шымкент. ул.Сайрамская, уг Туркестанская
        { id: 315, name: 'Point 13', coordinates: [42.314340, 69.582413] },//г. Шымкент. район Пригородный. ул.Айбергенова
        { id: 316, name: 'Point 13', coordinates: [42.316187, 69.596045] },//г. Шымкент. ул.Туркестанская-Кунаева
        { id: 317, name: 'Point 13', coordinates: [42.312879, 69.585027] },//г. Шымкент. ул.Жангильдина, район ост. Айгуль
        { id: 318, name: 'Point 13', coordinates: [42.276859, 69.667957] },//г. Шымкент. ул.Капал батыра
        { id: 319, name: 'Point 13', coordinates: [42.293463, 69.625345] },//г. Шымкент. ул.Подвойского
        { id: 320, name: 'Point 13', coordinates: [42.316341, 69.617786] },//г. Шымкент. 112 квартал. ул.Иляева
        { id: 321, name: 'Point 13', coordinates: [42.341393, 69.659523] },//г. Шымкент. мкр-он Мирас
        { id: 322, name: 'Point 13', coordinates: [42.385506, 69.640805] },//г. Шымкент. мкр-он Кайтпас. ул.Нурмекен
        { id: 323, name: 'Point 13', coordinates: [42.339188, 69.601165] },//г. Шымкент. ул. Байтурсынова б/н. Торговый центр Керемет
        { id: 324, name: 'Point 13', coordinates: [42.350402, 69.571411] },//г. Шымкент. ул.Рыскулова б/н, район Самал базар
        { id: 325, name: 'Point 13', coordinates: [42.345280, 69.672931] },//г. Шымкент. мкр-он Болашак. ул. Пахтакор 2.
        { id: 326, name: 'Point 13', coordinates: [42.373829, 69.552765] },//г. Шымкент. мкр-он Самал-3.
        { id: 327, name: 'Point 13', coordinates: [42.311515, 69.577613] },//г. Шымкент. район Пригородное. участок 113
        { id: 328, name: 'Point 13', coordinates: [42.381690, 69.501037] },//г. Шымкент. р-н Кирова. Дачный массив Турлан. ул.1Май б\н
        { id: 329, name: 'Point 13', coordinates: [42.309649, 69.600335] },//г. Шымкент. ул.Казыбек би, площадь Ордабасы
        { id: 330, name: 'Point 13', coordinates: [42.313857, 69.630893] },//г. Шымкент. ул.Елшибек батыра. ТОО Ел Кол Тобы
        { id: 331, name: 'Point 13', coordinates: [42.309371, 69.584094] },//г. Шымкент. ул.Жангильдина-Калдаякова
        { id: 751, name: 'Point 13', coordinates: [42.360876, 69.700008] },//г. Шымкент. мкр-он Тассай. вышка СПК
        { id: 752, name: 'Point 13', coordinates: [42.343237, 69.602719] },//г. Шымкент. ул Байтурсынова б/н, район гостиница Орда
        { id: 753, name: 'Point 13', coordinates: [42.403297, 69.575828] },//г. Шымкент. мкр Асар-2. ул.Сырым Батыра новостройка.
        { id: 754, name: 'Point 13', coordinates: [42.381936, 69.646740] },//г. Шымкент. мкр-он Кайтпас-1. ул.Кошерова б/н
        { id: 755, name: 'Point 13', coordinates: [42.382154, 69.629524] },//г. Шымкент. пр. Байдибек би б/н. АМС Кайтпас
        { id: 756, name: 'Point 13', coordinates: [42.353294, 69.557041] },//г .Шымкент. мкр-он.Катын-Копир. ул.Амангельды
        { id: 757, name: 'Point 13', coordinates: [42.419312, 69.580103] },//г. Шымкент. мкр-он Асар 2
        { id: 758, name: 'Point 13', coordinates: [42.373794, 69.551890] },//г. Шымкент. мкр-он Самал-3
        { id: 759, name: 'Point 13', coordinates: [42.339482, 69.602085] },//г. Шымкент. ул.Жандосова, БЦ СОДБИ
        { id: 760, name: 'Point 13', coordinates: [42.377314, 69.603442] },//г. Шымкент. ул.Толеметова б/н. заправка SinOil
        { id: 761, name: 'Point 13', coordinates: [42.368773, 69.644227] },//г. Шымкент. ул.Адырбекова б/н. магазин Сайман
        { id: 762, name: 'Point 13', coordinates: [42.360698, 69.647630] },//г. Шымкент. мкр-он Нуртас 2
        { id: 763, name: 'Point 13', coordinates: [42.301585, 69.534629] },//г. Шымкент. Ташкентская трасса. с. Курсай.
        { id: 764, name: 'Point 13', coordinates: [42.351654, 69.575387] },//г. Шымкент. мкр-он Самал-1. ул. Рыскулова, район ТЦ Муса
        { id: 765, name: 'Point 13', coordinates: [42.403212, 69.560750] },//г. Шымкент. мкр-он Достык
        { id: 766, name: 'Point 13', coordinates: [42.396458, 69.631145] },//г. Шымкент. мкр-он Туран. ул.Байдибек би
        { id: 767, name: 'Point 13', coordinates: [42.342210, 69.642327] },//г. Шымкент. мкр-он Север. возле мечети
        { id: 768, name: 'Point 13', coordinates: [42.346334, 69.682636] },//г. Шымкент. Пахтакор 2. 194 квартал
        { id: 769, name: 'Point 13', coordinates: [42.327218, 69.628908] },//г. Шымкент. ул.Жибек Жолы, район Айна базар
        { id: 770, name: 'Point 13', coordinates: [42.358068, 69.651434] },//г. Шымкент. пр.Нурсултана Назарбаева.ТЭЦ-3
        { id: 771, name: 'Point 13', coordinates: [42.341970, 69.607886] },//г. Шымкент. ул.Кунаева б/н. фитнес центр Рояль
        { id: 772, name: 'Point 13', coordinates: [42.335260, 69.644775] },//г. Шымкент. мкр-он Восток 106
        { id: 773, name: 'Point 13', coordinates: [42.353196, 69.590350] },//г. Шымкент. мкр-он Самал-1
        { id: 774, name: 'Point 13', coordinates: [42.333977, 69.599624] },//г. Шымкент. ул. Байтурсынова б/н. Налоговый Комитет
        { id: 775, name: 'Point 13', coordinates: [42.347645, 69.595316] },//г. Шымкент. мкр-он Самал. ул.Казиева.
        { id: 776, name: 'Point 13', coordinates: [42.426749, 69.651662] },//г. Шымкент. с. Бозарык.
        { id: 777, name: 'Point 13', coordinates: [42.318009, 69.604288] },//г. Шымкент. ул.Тауке Хана б/н. парк Кен-Баба
        { id: 778, name: 'Point 13', coordinates: [42.286645, 69.572505] },//г. Шымкент. п. Бадам. ул.Новостройка 5
        { id: 779, name: 'Point 13', coordinates: [42.336022, 69.656970] },//г. Шымкент. с.Пахтакор. ул.Жибек жолы б/н.
        { id: 780, name: 'Point 13', coordinates: [42.352927, 69.545558] },//г. Шымкент. мкр-он Катын копир. ул.Сапырган ата б/н.
        { id: 781, name: 'Point 13', coordinates: [42.373849, 69.582158] },//г. Шымкент. Северо - Запад микрорайон.
        { id: 782, name: 'Point 13', coordinates: [42.315091, 69.556486] },//г.Шымкент, проспект Абая 6, клиника Дау-Мед
        { id: 783, name: 'Point 13', coordinates: [42.314075, 69.585013] },//г. Шымкент. район Сарыарка.
        { id: 784, name: 'Point 13', coordinates: [42.392212, 69.657171] },//г. Шымкент. мкр-он Туран.192 квартал. Бетонный завод
        { id: 785, name: 'Point 13', coordinates: [42.360647, 69.647628] },//г. Шымкент, мкр. Нурсат, вышка, район синие дома
        { id: 786, name: 'Point 13', coordinates: [42.351598, 69.647882] },//г. Шымкент, улица Аргынбекова, район Тойханы LeMonde
        { id: 787, name: 'Point 13', coordinates: [42.363909, 69.559096] },//г. Шымкент. мкрн Самал-3
        { id: 788, name: 'Point 13', coordinates: [42.426338, 69.652550] },//г. Шымкент. район Бозарык. ул.Мырсаат-ата
        { id: 789, name: 'Point 13', coordinates: [42.327994, 69.612891] },//г. Шымкент. ул. Мадели Кожа, уг. Ташенова
        { id: 790, name: 'Point 13', coordinates: [42.303465, 69.593899] },//г. Шымкент. ул. Володарского, Автовокзал
        { id: 791, name: 'Point 13', coordinates: [42.298782, 69.562451] },//г. Шымкент. Сайрамский район. с.Таскен. ул.Мунайтпасова
        { id: 792, name: 'Point 13', coordinates: [42.278261, 69.667576] },//г. Шымкент. ул.Капал батыра
        { id: 793, name: 'Point 13', coordinates: [42.303618, 69.575094] },//г. Шымкент. ул.Республики б/н
        { id: 794, name: 'Point 13', coordinates: [42.308357, 69.565097] },//г. Шымкент. ул. Конституции б/н
        { id: 795, name: 'Point 13', coordinates: [42.329758, 69.606631] },//г. Шымкент. ул. Дулати, Мадели Кожа
        { id: 796, name: 'Point 13', coordinates: [42.338845, 69.554631] },//г. Шымкент. ул.Кызыл жулдыз 112 квартал
        { id: 797, name: 'Point 13', coordinates: [42.294111, 69.546594] },//г. Шымкент. мкр-он Казгурт б/н
        { id: 798, name: 'Point 13', coordinates: [42.367360, 69.667707] },//г. Шымкент. мкр.Нуртас
        { id: 799, name: 'Point 13', coordinates: [42.308587, 69.583912] },//г. Шымкент. ул.Жангильдина б/н
        { id: 800, name: 'Point 13', coordinates: [42.325292, 69.637392] },//г. Шымкент. ул.Валиханова, Сайрамская
        { id: 801, name: 'Point 13', coordinates: [42.437330, 69.539609] },//г. Шымкент. ж.м Кайнарбулак.
        { id: 802, name: 'Point 13', coordinates: [42.320224, 69.591791] },//г. Шымкент. пр.Тауке Хана. Горводопроект
        { id: 803, name: 'Point 13', coordinates: [42.328993, 69.631882] },//г. Шымкент. Пахтакор. ул.Аймауытова
        { id: 804, name: 'Point 13', coordinates: [42.280818, 69.617486] },//г. Шымкент. ул.Пищевиков
        { id: 805, name: 'Point 13', coordinates: [42.358135, 69.428741] },//г. Шымкент. район Жанаталап
        { id: 806, name: 'Point 13', coordinates: [42.357568, 69.609008] },//г. Шымкент. ул. Байтурсынова, ул. Мырзабекова
        { id: 807, name: 'Point 13', coordinates: [42.394172, 69.418803] },//г. Шымкент с.Алтынтобе
        { id: 808, name: 'Point 13', coordinates: [42.366533, 69.493146] },//г. Шымкент. Аэропорт.
        { id: 809, name: 'Point 13', coordinates: [42.301739, 69.534563] },//г. Шымкент. мкр-он Курсай.
        { id: 810, name: 'Point 13', coordinates: [42.321917, 69.593041] },//г. Шымкент. ул.Иляева б/н. Район ЮКГУ
        { id: 811, name: 'Point 13', coordinates: [42.359202, 69.628165] },//г. Шымкент. мкр-он.Нурсат. ул.Аргынбекова. комплекс Косбулак
        { id: 812, name: 'Point 13', coordinates: [42.270436, 69.837844] },//г. Шымкент. жилой массив Абдилабад
        { id: 813, name: 'Point 13', coordinates: [42.366548, 69.493125] },//г. Шымкент. НП западнее аэропорта. п.Ынтымак
        { id: 814, name: 'Point 13', coordinates: [42.256270, 69.733454] },//г. Шымкент. Сайрамский с/о.с.Сайрам. Мельница
        { id: 815, name: 'Point 13', coordinates: [42.328056, 69.570469] },//г. Шымкент. мкр-он Туран б/н
        { id: 816, name: 'Point 13', coordinates: [42.407913, 69.600909] },//г. Шымкент. мкр-он Асар, район школы №90
        { id: 817, name: 'Point 13', coordinates: [42.397645, 69.614391] },//г. Шымкент. мкр-он Шымсити. восточная часть б/н
        { id: 818, name: 'Point 13', coordinates: [42.324699, 69.609504] },//г. Шымкент. ул.Диваева
        { id: 819, name: 'Point 13', coordinates: [42.340130, 69.638233] },//г. Шымкент. мкр-он Север, район ТЭЦ №3
        { id: 820, name: 'Point 13', coordinates: [42.390897, 69.596193] },//г. Шымкент, мкр. Акжайык, ул. Сырым Батыра
        { id: 821, name: 'Point 13', coordinates: [42.438745, 69.540932] },//г. Шымкент, Абайский район, ж. м. Кайнарбулак, вышка
        { id: 822, name: 'Point 13', coordinates: [42.309784, 69.629705] },//г. Шымкент, пр. Тауке хана, район Парк Победы
        { id: 823, name: 'Point 13', coordinates: [42.315772, 69.579241] },//г. Шымкент, пр. Республика 12а
        { id: 824, name: 'Point 13', coordinates: [42.365922, 69.524777] },//г. Шымкент, Абайский район, мкр. Ынтымак, Вышка. Рынок Базар
        { id: 825, name: 'Point 13', coordinates: [42.349381, 69.641582] },//г.Шымкент, ул. Жумабай Шаяхметова
        { id: 826, name: 'Point 13', coordinates: [42.324534, 69.586167] },//г. Шымкент, просп. Момышулы, Желтоксан
        { id: 827, name: 'Point 13', coordinates: [42.361031, 69.628568] },//г. Шымкент, мкр. Нурсат, Вышка
        { id: 828, name: 'Point 13', coordinates: [42.315466, 69.633914] },//г. Шымкент, ул. Желтоксан, район кафе Теремок
        { id: 829, name: 'Point 13', coordinates: [42.362386, 69.520434] },//г. Шымкент, на территории производственного кооператива "Ак-ниет", по Тамерланскому шосее, б/н,
        { id: 830, name: 'Point 13', coordinates: [42.325537, 69.590257] },//г. Шымкент, ул. Желтоксан, ул Рыскулбекова
        { id: 831, name: 'Point 13', coordinates: [42.205601, 69.804587] },//г. Шымкент, Енбекшинский район ж.м. Тогус.
        { id: 832, name: 'Point 13', coordinates: [42.324297, 69.582131] },//г. Шымкент, Абайский район трасса Темирлановское, Желтоксан
        { id: 833, name: 'Point 13', coordinates: [42.302322, 69.534028] },//г. Шымкент, Туранский район, мкр. Курсай, Вышка
        { id: 834, name: 'Point 13', coordinates: [42.315208, 69.587932] },//г. Шымкент, Аль-Фарабийский район, пл. Аль-Фараби
        { id: 835, name: 'Point 13', coordinates: [42.313991, 69.601709] },//г. Шымкент, Аль-Фарабийский район, ул. Казыбек би, Туркестанская
        { id: 836, name: 'Point 13', coordinates: [42.303546, 69.603651] },//г. Шымкент, Аль-Фарабийский район, пр. Кабанбай Батыра
        { id: 837, name: 'Point 13', coordinates: [42.332332, 69.581245] },//г. Шымкент, Абайский район, пр.Б. Момышулы, Кожанова
        { id: 838, name: 'Point 13', coordinates: [42.285085, 69.595029] },//г. Шымкент, ул. Баян батыра
        { id: 839, name: 'Point 13', coordinates: [42.299351, 69.758781] },//г. Шымкент, Енбекшинский район, жилой массив Сайрам, Вышка Кселл
        { id: 840, name: 'Point 13', coordinates: [42.359808, 69.580160] },//г. Шымкент, Абайский район, мкр. Шугыла,
        { id: 841, name: 'Point 13', coordinates: [42.254060, 69.733700] },//г. Шымкент, Енбекшинский район, Ленгерское шоссе б/н
        { id: 842, name: 'Point 13', coordinates: [42.321454, 69.617081] },//г. Шымкент, Енбекшинский район, ул. Акпан батыр,
        { id: 843, name: 'Point 13', coordinates: [42.350618, 69.586297] },//г. Шымкент, Туранский район, ул. Рыскулова, б/н, Подстанция РЭС №5, вышка
        { id: 844, name: 'Point 13', coordinates: [42.321868, 69.619650] },//г. Шымкент, ул. Сасбукаева
        { id: 845, name: 'Point 13', coordinates: [42.399536, 69.651244] },//г. Шымкент, Каратауский район, мкр. Туран, Вышка
        { id: 846, name: 'Point 13', coordinates: [42.316354, 69.626810] },//г. Шымкент, Енбекшинский район, ул. Аймауытова,
        { id: 847, name: 'Point 13', coordinates: [42.315132, 69.587779] },//г. Шымкент, Аль-Фарабийский район, пл. Аль-Фараби
        { id: 848, name: 'Point 13', coordinates: [42.328602, 69.567323] },//г.Шымкент, Тамерлановское шоссе, Автонур
        { id: 849, name: 'Point 13', coordinates: [42.346231, 69.560928] },//г. Шымкент, Абайский район, ул. Т.Рыскулова
        { id: 850, name: 'Point 13', coordinates: [42.327814, 69.610613] },//г. Шымкент, Аль-Фарабийский район, ул.Диваева
        { id: 851, name: 'Point 13', coordinates: [42.397534, 69.631112] },//г. Шымкент, проспект Байдибек би, Шымкент Сити Молл
        { id: 852, name: 'Point 13', coordinates: [42.365187, 69.630417] },//г.Шымкент, Каратауский район, ж.м. Нурсат, ул.Назарбаева
        { id: 853, name: 'Point 13', coordinates: [42.350671, 69.753983] },//г. Шымкент, Каратауский район, ж.м.Тассай, Вышка
        { id: 854, name: 'Point 13', coordinates: [42.318416, 69.627938] },//г.Шымкент, Аль-Фарабийский район, ул. Тостого
        { id: 855, name: 'Point 13', coordinates: [42.276286, 69.668995] },//г. Шымкент, Енбекшинский район, ул. Капал Батыра, тер. Ондиристик, Вышка
        { id: 856, name: 'Point 13', coordinates: [42.287320, 69.784760] },//г.Шымкент, Енбекшинский район, жилой массив Базаркакпа, ул. Жамалова, б/н., водонапорная башня
        { id: 857, name: 'Point 13', coordinates: [42.335945, 69.577232] },//г.Шымкент, Туранский район, мкр.4, ул. Байдибек Би
        { id: 858, name: 'Point 13', coordinates: [42.331726, 69.616982] },//г.Шымкент, Енбекшинский район, ул. Койгелди Батыр, Башня
        { id: 859, name: 'Point 13', coordinates: [42.205725, 69.803724] },//г. Шымкент, Енбекшинский район, жилой массив Тогус
        { id: 860, name: 'Point 13', coordinates: [42.297127, 69.642469] },//г. Шымкент, Ыскакова, мкр. Лесхоз, мачта СПК
        { id: 861, name: 'Point 13', coordinates: [42.315748, 69.644444] },//г. Шымкент, Енбекшинский район, ул. Сайрамская, вышка
        { id: 862, name: 'Point 13', coordinates: [42.345456, 69.613881] },//г. Шымкент, Туранский район, ул. М.Х.Дулати, Hyper House
        { id: 863, name: 'Point 13', coordinates: [42.411832, 69.630340] },//г. Шымкент, 189 квартал дом 392/1
        { id: 864, name: 'Point 13', coordinates: [42.324374, 69.641192] },//г.Шымкент,ул Валиханова д 198а магазин алатау\

        { id: 865, name: 'Point 13', coordinates: [42.360854, 69.627744] },//г.Шымкент, ж.м. Орманшы зд 143
        { id: 866, name: 'Point 13', coordinates: [42.301105, 69.584194] },//г.Шымкент, ул. Рашидова зд 14А
        { id: 867, name: 'Point 13', coordinates: [42.297691, 69.544263] },//г.Шымкент, ул. Жибек жолы зд 20
        { id: 868, name: 'Point 13', coordinates: [42.365837, 69.624393] },//г.Шымкент,мкр Нурсат д. 64А
        { id: 869, name: 'Point 13', coordinates: [42.205725, 69.803724] },//г.Шымкент, ж.м. Тогыс учетныйквартал уч. 2206
        { id: 870, name: 'Point 13', coordinates: [42.342990, 69.623971] },//г.Шымкент, ул. Байтерекова зд 1/1
        { id: 871, name: 'Point 13', coordinates: [42.293089, 69.541308] },//г.Шымкент, ул. Жибек жолы д. 66/1
        { id: 872, name: 'Point 13', coordinates: [42.358295, 69.499060] },//г.Шымкент, мкр. Кызылжар, зд. 1218/1
        { id: 873, name: 'Point 13', coordinates: [42.360854, 69.627744] },//г.Шымкент, ж.м. Көкбулақ вышка
        { id: 874, name: 'Point 13', coordinates: [42.321797, 69.560002] },//г.Шымкент, пр. Темирлан зд 63А
        { id: 875, name: 'Point 13', coordinates: [42.333573, 69.522390] },//г.Шымкент, мкр. Кызылжар, ул Ташбулатова зд. 39
        { id: 876, name: 'Point 13', coordinates: [42.293802, 69.531274] },//г.Шымкент, мкр. Казыгурт, ул Бухарская зд. 70
        { id: 877, name: 'Point 13', coordinates: [42.272564, 69.595450] },//г.Шымкент, ул. Уалиханова д. 213/12
        { id: 878, name: 'Point 13', coordinates: [42.319039, 69.581952] },//г.Шымкент,ул Республика д 6 гостиница Шымкент
        { id: 879, name: 'Point 13', coordinates: [42.321061, 69.600750] },//г.Шымкент, ул. Г. Иляева зд 47
        { id: 880, name: 'Point 13', coordinates: [42.297350, 69.551432] },//г.Шымкент, мкр. Казыгурт, ул Туркистан №2/5
        { id: 881, name: 'Point 13', coordinates: [42.372918, 69.500120] },//г.Шымкент, мкр. Ынтымак д 405
        { id: 882, name: 'Point 13', coordinates: [42.406733, 69.598980] },//г.Шымкент, мкр. Асар вышка
        { id: 883, name: 'Point 13', coordinates: [42.357249, 69.676990] },//г.Шымкент, мкр. Нуртас, новостройка  2122
        { id: 884, name: 'Point 13', coordinates: [42.298404, 69.551252] },//г.Шымкент, мкр. Казыгурт, ул Курылыс №2 В
        { id: 885, name: 'Point 13', coordinates: [42.399975, 69.603867] },//г. Шымкент, Абайский район, мкр. Асар, уч. 1427, на трубе,
        { id: 886, name: 'Point 13', coordinates: [42.374343, 69.583017] },//г.Шымкент, Абайский район, мкр. северо-запад
        { id: 887, name: 'Point 13', coordinates: [42.299471, 69.551001] },//г. Шымкент, Абайский район, мкр. Казыгурт, ул.Курылыс
        { id: 888, name: 'Point 13', coordinates: [42.304686, 69.647461] },//г.Шымкент, Енбекшинский район, просп. Тауке хана, ТРЦ "Фиркан Сити Фанки Таун
        { id: 889, name: 'Point 13', coordinates: [42.327056, 69.544103] },//г. Шымкент, улица Гагарина
        { id: 890, name: 'Point 13', coordinates: [42.398224, 69.645674] },//г. Шымкент, мкр Туран, ул. Новостройка д.361
        { id: 891, name: 'Point 13', coordinates: [42.313667, 69.615850] },//г. Шымкент,  пр. Тауке хана д.126
        { id: 892, name: 'Point 13', coordinates: [42.346102, 69.582038] },//г. Шымкент, 112 квартал, ул. Тажибаева д.118 а
        { id: 893, name: 'Point 13', coordinates: [42.277861, 69.595450] },//г. Шымкент, улица Гажайып,15 
        { id: 894, name: 'Point 13', coordinates: [42.343650, 69.626729] },//г. Шымкент, улица Рыскулова 27/2
        { id: 895, name: 'Point 13', coordinates: [42.359080, 69.579305] },//г. Шымкент, мкр Шұғыла 67
        { id: 896, name: 'Point 13', coordinates: [42.285459, 69.664063] },//г. Шымкент, улица Жастар д 118 а
        { id: 897, name: 'Point 13', coordinates: [42.397920, 69.647572] },//г.Шымкент, Каратауский район, мкр. Туран, ул. Новостройки, Вышка
        { id: 898, name: 'Point 13', coordinates: [42.341729, 69.585994] },//г.Шымкент, 112 квартал, Тажибаева, Вышка
        { id: 899, name: 'Point 13', coordinates: [42.310851, 69.630637] },//г.Шымкент, Енбекшинский район, пр.Тауке-хана(район гостиницы Кема)
        { id: 900, name: 'Point 13', coordinates: [42.277001, 69.595277] },//г.Шымкент, улица Гажаиып, Вышка
        { id: 901, name: 'Point 13', coordinates: [42.343861, 69.625473] },//г.Шымкент, Аль-Фарабийский район, пр. ул. Рыскулова(район Хюндай центр)
        { id: 902, name: 'Point 13', coordinates: [42.358293, 69.579499] },//г.Шымкент, Абайский район, мкр. Шугыла, Вышка
        { id: 903, name: 'Point 13', coordinates: [42.294763, 69.637221] },//г.Шымкент, Енбекшинский район, д.118А
        { id: 904, name: 'Point 13', coordinates: [42.267887, 69.595530] },//г.Шымкент, Аль-Фарабийский район, квартал 275, вышка
        { id: 905, name: 'Point 13', coordinates: [42.379971, 69.675579] },//г.Шымкент, Каратауский район, кварт. 192,(район Бектан базара)
        { id: 906, name: 'Point 13', coordinates: [42.392368, 69.651137] },//г. Шымкент, Каратауский район, мкр.Туран, ул. Новостройки, вышка
        

        // Другие точки оператора 1...

        // Kcell БС / 4G *******************************************************************************************************
      ],
      pointIcon: icon1// Замените на путь к иконке точки оператора 1
    },
    {
      id: 2,
      name: 'Kcell БС',
      points: [
        { id: 13, name: 'Point 13', coordinates: [42.376083, 69.554333] },
        { id: 14, name: 'Point 13', coordinates: [42.4282219, 69.6125166] },
        { id: 15, name: 'Point 13', coordinates: [42.2623344, 69.5790533] },
        { id: 15, name: 'Point 13', coordinates: [42.405550, 69.5790533] },
        { id: 17, name: 'Point 13', coordinates: [42.2400406, 69.7812725] },
        { id: 18, name: 'Point 13', coordinates: [42.4230182, 69.6360725] },
        { id: 19, name: 'Point 13', coordinates: [42.4344719, 69.529811] },
        { id: 20, name: 'Point 13', coordinates: [42.4367813, 69.5504505] },
        { id: 21, name: 'Point 13', coordinates: [42.37305, 69.4793552] },
        { id: 22, name: 'Point 13', coordinates: [42.178295, 69.843386] },
        { id: 23, name: 'Point 13', coordinates: [42.310895, 69.723192] },
        { id: 24, name: 'Point 13', coordinates: [42.370109, 69.659732] },
        { id: 25, name: 'Point 13', coordinates: [42.379244, 69.642764] },
        { id: 26, name: 'Point 13', coordinates: [42.338795, 69.50116] },
        { id: 27, name: 'Point 13', coordinates: [42.309761, 69.580477] },
        { id: 28, name: 'Point 13', coordinates: [42.301079, 69.600517] },
        { id: 39, name: 'Point 13', coordinates: [42.300727, 69.593221] },
        { id: 30, name: 'Point 13', coordinates: [42.32646, 69.631807] },
        { id: 31, name: 'Point 13', coordinates: [42.29891, 69.768779] },
        { id: 32, name: 'Point 13', coordinates: [42.293302, 69.76432] },
        { id: 33, name: 'Point 13', coordinates: [42.279493, 69.544796] },
        { id: 34, name: 'Point 13', coordinates: [42.313234, 69.584524] },
        { id: 35, name: 'Point 13', coordinates: [42.316581, 69.58279] },
        { id: 36, name: 'Point 13', coordinates: [42.373525, 69.489543] },
        { id: 37, name: 'Point 13', coordinates: [42.354419, 69.50762] },
        { id: 38, name: 'Point 13', coordinates: [42.3538284, 69.5140305] },
        { id: 39, name: 'Point 13', coordinates: [42.328125, 69.664089] },
        { id: 40, name: 'Point 13', coordinates: [42.350019, 69.572325] },
        { id: 41, name: 'Point 13', coordinates: [42.350662, 69.562431] },
        { id: 42, name: 'Point 13', coordinates: [42.27101, 69.714046] },
        { id: 43, name: 'Point 13', coordinates: [42.335995, 69.632831] },
        { id: 44, name: 'Point 13', coordinates: [42.330665, 69.631368] },
        { id: 45, name: 'Point 13', coordinates: [42.325235, 69.579978] },
        { id: 46, name: 'Point 13', coordinates: [42.308272, 69.625427] },
        { id: 47, name: 'Point 13', coordinates: [42.338573, 69.628427] },
        { id: 48, name: 'Point 13', coordinates: [42.335379, 69.529644] },
        { id: 49, name: 'Point 13', coordinates: [42.353715, 69.686195] },
        { id: 50, name: 'Point 13', coordinates: [42.320038, 69.579029] },
        { id: 51, name: 'Point 13', coordinates: [42.34468, 69.610354] },
        { id: 52, name: 'Point 13', coordinates: [42.319038, 69.631473] },
        { id: 53, name: 'Point 13', coordinates: [42.344111, 69.642329] },
        { id: 54, name: 'Point 13', coordinates: [42.349937, 69.635264] },
        { id: 55, name: 'Point 13', coordinates: [42.35486, 69.604504] },
        { id: 56, name: 'Point 13', coordinates: [42.315373, 69.605296] },
        { id: 57, name: 'Point 13', coordinates: [42.314864, 69.627226] },
        { id: 58, name: 'Point 13', coordinates: [42.297666, 69.613232] },
        { id: 59, name: 'Point 13', coordinates: [42.438546, 69.558393] },
        { id: 60, name: 'Point 13', coordinates: [42.430176, 69.550498] },
        { id: 61, name: 'Point 13', coordinates: [42.424699, 69.563137] },
        { id: 62, name: 'Point 13', coordinates: [42.278668, 69.564294] },
        { id: 63, name: 'Point 13', coordinates: [42.318905, 69.55747] },
        { id: 64, name: 'Point 13', coordinates: [42.332185, 69.596182] },
        { id: 65, name: 'Point 13', coordinates: [42.342396, 69.621256] },
        { id: 66, name: 'Point 13', coordinates: [42.337404, 69.640793] },
        { id: 67, name: 'Point 13', coordinates: [42.354027, 69.537616] },
        { id: 68, name: 'Point 13', coordinates: [42.378751, 69.712905] },
        { id: 69, name: 'Point 13', coordinates: [42.373272, 69.690246] },
        { id: 70, name: 'Point 13', coordinates: [42.383134, 69.69442] },
        { id: 71, name: 'Point 13', coordinates: [42.321074, 69.609587] },
        { id: 72, name: 'Point 13', coordinates: [42.303595, 69.631066] },
        { id: 73, name: 'Point 13', coordinates: [42.339507, 69.539061] },
        { id: 74, name: 'Point 13', coordinates: [42.336439, 69.600084] },
        { id: 75, name: 'Point 13', coordinates: [42.363197, 69.541569] },
        { id: 76, name: 'Point 13', coordinates: [42.362865, 69.532589] },
        { id: 77, name: 'Point 13', coordinates: [42.29657, 69.557996] },
        { id: 78, name: 'Point 13', coordinates: [42.391995, 69.633752] },
        { id: 79, name: 'Point 13', coordinates: [42.383537, 69.633182] },
        { id: 80, name: 'Point 13', coordinates: [42.341686, 69.573455] },
        { id: 81, name: 'Point 13', coordinates: [42.355146, 69.570944] },
        { id: 82, name: 'Point 13', coordinates: [42.359689, 69.623068] },
        { id: 83, name: 'Point 13', coordinates: [42.254433, 69.632165] },
        { id: 84, name: 'Point 13', coordinates: [42.365186, 69.563293] },
        { id: 85, name: 'Point 13', coordinates: [42.356162, 69.554676] },
        { id: 86, name: 'Point 13', coordinates: [42.315508, 69.574325] },
        { id: 87, name: 'Point 13', coordinates: [42.277316, 69.59442] },
        { id: 88, name: 'Point 13', coordinates: [42.327908, 69.567919] },
        { id: 89, name: 'Point 13', coordinates: [42.320721, 69.542564] },
        { id: 90, name: 'Point 13', coordinates: [42.286338, 69.635481] },
        { id: 91, name: 'Point 13', coordinates: [42.358267, 69.705347] },
        { id: 92, name: 'Point 13', coordinates: [42.32991, 69.531743] },
        { id: 93, name: 'Point 13', coordinates: [42.33381, 69.517282] },
        { id: 94, name: 'Point 13', coordinates: [42.392466, 69.569813] },
        { id: 95, name: 'Point 13', coordinates: [42.39614, 69.552282] },
        { id: 96, name: 'Point 13', coordinates: [42.43922, 69.822675] },
        { id: 97, name: 'Point 13', coordinates: [42.434382, 69.825305] },
        { id: 98, name: 'Point 13', coordinates: [42.431926, 69.819492] },
        { id: 99, name: 'Point 13', coordinates: [42.289947, 69.673677] },
        { id: 100, name: 'Point 13', coordinates: [42.280151, 69.679215] },
        { id: 101, name: 'Point 13', coordinates: [42.287107, 69.663059] },
        { id: 102, name: 'Point 13', coordinates: [42.35611, 69.592112] },
        { id: 103, name: 'Point 13', coordinates: [42.350704, 69.46495] },
        { id: 104, name: 'Point 13', coordinates: [42.449468, 69.528249] },
        { id: 105, name: 'Point 13', coordinates: [42.354346, 69.65724] },
        { id: 106, name: 'Point 13', coordinates: [42.411379, 69.56253] },
        { id: 107, name: 'Point 13', coordinates: [42.412569, 69.605811] },
        { id: 108, name: 'Point 13', coordinates: [42.406414, 69.592334] },
        { id: 109, name: 'Point 13', coordinates: [42.410836, 69.582139] },
        { id: 110, name: 'Point 13', coordinates: [42.391411, 69.583467] },
        { id: 111, name: 'Point 13', coordinates: [42.333866, 69.662891] },
        { id: 112, name: 'Point 13', coordinates: [42.378911, 69.509555] },
        { id: 113, name: 'Point 13', coordinates: [42.38301, 69.492628] },
        { id: 114, name: 'Point 13', coordinates: [42.389555, 69.497784] },
        { id: 115, name: 'Point 13', coordinates: [42.273964, 69.627662] },
        { id: 116, name: 'Point 13', coordinates: [42.264435, 69.628247] },
        { id: 117, name: 'Point 13', coordinates: [42.371995, 69.547045] },
        { id: 118, name: 'Point 13', coordinates: [42.374793, 69.66681] },
        { id: 119, name: 'Point 13', coordinates: [42.379222, 69.651821] },
        { id: 120, name: 'Point 13', coordinates: [42.285631, 69.784682] },
        { id: 121, name: 'Point 13', coordinates: [42.341556, 69.59725] },
        { id: 122, name: 'Point 13', coordinates: [42.290319, 69.514346] },
        { id: 123, name: 'Point 13', coordinates: [42.429122, 69.635391] },
        { id: 124, name: 'Point 13', coordinates: [42.383426, 69.549151] },
        { id: 125, name: 'Point 13', coordinates: [42.3715006, 69.577677] },
        { id: 126, name: 'Point 13', coordinates: [42.274155, 69.57459] },
        { id: 127, name: 'Point 13', coordinates: [42.307361, 69.577229] },
        { id: 128, name: 'Point 13', coordinates: [42.386623, 69.646502] },

        { id: 129, name: 'Point 13', coordinates: [42.425051, 69.830723] },

        { id: 130, name: 'Point 13', coordinates: [42.41912, 69.839389] },

        { id: 131, name: 'Point 13', coordinates: [42.415104, 69.823472] },

        { id: 132, name: 'Point 13', coordinates: [42.351135, 69.58313] },

        { id: 133, name: 'Point 13', coordinates: [42.359684, 69.527572] },

        { id: 134, name: 'Point 13', coordinates: [42.366139, 69.6957] },

        { id: 135, name: 'Point 13', coordinates: [42.3971492, 69.6114991] },

        { id: 136, name: 'Point 13', coordinates: [42.400231, 69.586986] },

        { id: 137, name: 'Point 13', coordinates: [42.378947, 69.596439] },

        { id: 138, name: 'Point 13', coordinates: [42.372795, 69.591329] },

        { id: 139, name: 'Point 13', coordinates: [42.356241, 69.645466] },

        { id: 140, name: 'Point 13', coordinates: [42.354865, 69.642466] },

        { id: 141, name: 'Point 13', coordinates: [42.359484, 69.676012] },

        { id: 142, name: 'Point 13', coordinates: [42.393051, 69.643317] },

        { id: 143, name: 'Point 13', coordinates: [42.381468, 69.629918] },

        { id: 144, name: 'Point 13', coordinates: [42.367756, 69.649597] },

        { id: 145, name: 'Point 13', coordinates: [42.367734, 69.603302] },

        { id: 146, name: 'Point 13', coordinates: [42.309299, 69.513778] },

        { id: 147, name: 'Point 13', coordinates: [42.325115, 69.559319] },

        { id: 148, name: 'Point 13', coordinates: [42.3820084, 69.5296831] },

        { id: 149, name: 'Point 13', coordinates: [42.307393, 69.524477] },

        { id: 150, name: 'Point 13', coordinates: [42.398183, 69.645692] },

        { id: 151, name: 'Point 13', coordinates: [42.362414, 69.655482] },

        { id: 152, name: 'Point 13', coordinates: [42.360198, 69.648469] },

        { id: 153, name: 'Point 13', coordinates: [42.348202, 69.668442] },

        { id: 154, name: 'Point 13', coordinates: [42.364312, 69.677663] },

        { id: 155, name: 'Point 13', coordinates: [42.362776, 69.583312] },

        { id: 156, name: 'Point 13', coordinates: [42.352389, 69.657176] },

        { id: 157, name: 'Point 13', coordinates: [42.347374, 69.647612] },

        { id: 158, name: 'Point 13', coordinates: [42.280517, 69.6254] },

        { id: 159, name: 'Point 13', coordinates: [42.360043, 69.630299] },

        { id: 160, name: 'Point 13', coordinates: [42.318967, 69.662786] },

        { id: 161, name: 'Point 13', coordinates: [42.358254, 69.450216] },

        { id: 162, name: 'Point 13', coordinates: [42.326731, 69.603384] },

        { id: 163, name: 'Point 13', coordinates: [42.3957061, 69.5785098] },

        { id: 164, name: 'Point 13', coordinates: [42.325224, 69.684212] },

        { id: 165, name: 'Point 13', coordinates: [42.459501, 69.545805] },

        { id: 166, name: 'Point 13', coordinates: [42.457739, 69.51425] },

        { id: 167, name: 'Point 13', coordinates: [42.335141, 69.628173] },

        { id: 168, name: 'Point 13', coordinates: [42.341849, 69.548743] },

        { id: 169, name: 'Point 13', coordinates: [42.369175, 69.664945] },

        { id: 170, name: 'Point 13', coordinates: [42.377947, 69.596974] },

        { id: 171, name: 'Point 13', coordinates: [42.79949, 69.597481] },

        { id: 172, name: 'Point 13', coordinates: [42.3666621, 69.5114795] },

        { id: 173, name: 'Point 13', coordinates: [42.359756, 69.657805] },

        { id: 174, name: 'Point 13', coordinates: [42.409328, 69.614712] },

        { id: 175, name: 'Point 13', coordinates: [42.41024, 69.634774] },

        { id: 176, name: 'Point 13', coordinates: [42.403714, 69.614321] },
        
        { id: 177, name: 'Point 13', coordinates: [42.400276, 69.555369] },

        { id: 178, name: 'Point 13', coordinates: [42.410418, 69.532136] },

        { id: 179, name: 'Point 13', coordinates: [42.400282, 69.857858] },

        { id: 180, name: 'Point 13', coordinates: [42.387263, 69.84226] },

        { id: 181, name: 'Point 13', coordinates: [42.412349, 69.799716] },

        { id: 182, name: 'Point 13', coordinates: [42.552407, 69.656352] },

        { id: 183, name: 'Point 13', coordinates: [42.529648, 69.668102] },

        { id: 184, name: 'Point 13', coordinates: [42.470093, 69.653953] },

        { id: 185, name: 'Point 13', coordinates: [42.466139, 69.624668] },

        { id: 186, name: 'Point 13', coordinates: [42.482248, 69.631673] },

        { id: 187, name: 'Point 13', coordinates: [42.529128, 69.778659] },

        { id: 188, name: 'Point 13', coordinates: [42.20786, 69.804281] },

        { id: 189, name: 'Point 13', coordinates: [42.381907, 69.909029] },

        { id: 190, name: 'Point 13', coordinates: [42.385388, 69.875738] },

        { id: 191, name: 'Point 13', coordinates: [42.324948, 69.777045] },

        { id: 192, name: 'Point 13', coordinates: [42.341651, 69.685848] },

        { id: 193, name: 'Point 13', coordinates: [42.337156, 69.675597] },

        { id: 194, name: 'Point 13', coordinates: [42.171939, 69.871284] },

        { id: 195, name: 'Point 13', coordinates: [42.177454, 69.85599] },

        { id: 196, name: 'Point 13', coordinates: [42.392581, 69.782759] },

        { id: 197, name: 'Point 13', coordinates: [42.434326, 69.846621] },
        
        { id: 198, name: 'Point 13', coordinates: [42.269715, 69.850114] },

        { id: 199, name: 'Point 13', coordinates: [42.551764, 69.769813] },

        { id: 200, name: 'Point 13', coordinates: [42.444146, 69.816504] },

        { id: 201, name: 'Point 13', coordinates: [42.464022, 69.749887] },

        { id: 202, name: 'Point 13', coordinates: [42.440373, 69.784605] },

        { id: 203, name: 'Point 13', coordinates: [42.355904, 69.41762] },

        { id: 204, name: 'Point 13', coordinates: [42.337037, 69.76258] },
        { id: 205, name: 'Point 13', coordinates: [42.519866, 69.790537] },
        { id: 206, name: 'Point 13', coordinates: [42.406552, 69.78713] },
        { id: 207, name: 'Point 13', coordinates: [42.421027, 69.772646] },
        { id: 208, name: 'Point 13', coordinates: [42.537398, 69.782122] },
        { id: 209, name: 'Point 13', coordinates: [42.170324, 69.884287] },
        { id: 210, name: 'Point 13', coordinates: [42.193633, 69.818209] },
        { id: 211, name: 'Point 13', coordinates: [42.505261, 69.71001] },
        { id: 212, name: 'Point 13', coordinates: [42.488999, 69.733471] },
        { id: 213, name: 'Point 13', coordinates: [42.2109436, 69.5240043] },
        { id: 214, name: 'Point 13', coordinates: [42.350324, 69.661859] },
        { id: 215, name: 'Point 13', coordinates: [42.315119, 69.784292] },
        { id: 216, name: 'Point 13', coordinates: [42.311902, 69.769795] },
        { id: 217, name: 'Point 13', coordinates: [42.447417, 69.839348] },
        { id: 218, name: 'Point 13', coordinates: [42.365255, 69.732979] },
        { id: 219, name: 'Point 13', coordinates: [42.277197, 69.608858] },
        { id: 220, name: 'Point 13', coordinates: [42.416512, 69.551372] },
        { id: 221, name: 'Point 13', coordinates: [42.3664957, 69.4139716] },
        { id: 222, name: 'Point 13', coordinates: [42.395181, 69.681747] },
        { id: 332, name: 'Point 13', coordinates: [42.32242955050825, 69.5777855800838] },
        { id: 333, name: 'Point 13', coordinates: [42.32583270843056, 69.62783445640608] },
        { id: 334, name: 'Point 13', coordinates: [42.254174441671445, 69.73559796968173] },
        { id: 335, name: 'Point 13', coordinates: [42.35378090030465, 69.70066715310475] },
        { id: 336, name: 'Point 13', coordinates: [42.30441920272266, 69.61589478193787] },
        { id: 337, name: 'Point 13', coordinates: [42.326460672310546, 69.63033785629116] },
        { id: 338, name: 'Point 13', coordinates: [42.313489710218185, 69.6071555665926] },
        { id: 339, name: 'Point 13', coordinates: [42.34192486149116, 69.67241160893298] },
        { id: 340, name: 'Point 13', coordinates: [42.307197460874896, 69.57189995310156] },
        { id: 341, name: 'Point 13', coordinates: [42.3226607592658, 69.61591334404622] },
        { id: 342, name: 'Point 13', coordinates: [42.288966844306124, 69.60379587038804] },
        { id: 343, name: 'Point 13', coordinates: [42.30608627541849, 69.58046082612034] },
        { id: 344, name: 'Point 13', coordinates: [42.33603627817351, 69.64169586828564] },
        { id: 345, name: 'Point 13', coordinates: [42.40130590889399, 69.57304937934303] },
        { id: 346, name: 'Point 13', coordinates: [42.30483510666677, 69.57461684703776] },
        { id: 347, name: 'Point 13', coordinates: [42.32561432588398, 69.63532919542988] },
        { id: 348, name: 'Point 13', coordinates: [42.30540808995659, 69.58707126794297] },
        { id: 349, name: 'Point 13', coordinates: [42.32524417892552, 69.59079495431158] },
        { id: 350, name: 'Point 13', coordinates: [42.25795228140582, 69.70383766873802] },
        { id: 351, name: 'Point 13', coordinates: [42.30434276282473, 69.57445856814773] },
        { id: 352, name: 'Point 13', coordinates: [42.316570687083356, 69.61257540932921] },
        { id: 353, name: 'Point 13', coordinates: [42.3222203620679, 69.585732886584] },
        { id: 354, name: 'Point 13', coordinates: [42.33062664879661, 69.59733936659374] },
        { id: 355, name: 'Point 13', coordinates: [42.33665041819134, 69.64188731285053] },
        { id: 356, name: 'Point 13', coordinates: [42.32177912323339, 69.5740835455288] },
        { id: 357, name: 'Point 13', coordinates: [42.42316013178741, 69.63757549573495] },
        { id: 358, name: 'Point 13', coordinates: [42.25808326234622, 69.70349224029644] },
        { id: 359, name: 'Point 13', coordinates: [42.32550740027528, 69.8670834986315] },
        { id: 360, name: 'Point 13', coordinates: [42.297156686184465, 69.59055119542792] },
        { id: 361, name: 'Point 13', coordinates: [42.31868583020037, 69.6252930242659] },
        { id: 362, name: 'Point 13', coordinates: [42.24854227705978, 69.64121083657253] },
        { id: 363, name: 'Point 13', coordinates: [42.31751265353188, 69.67670998211528] },
        { id: 364, name: 'Point 13', coordinates: [42.337042519122214, 69.56689830769082] },
        { id: 365, name: 'Point 13', coordinates: [42.34423006187631, 69.6217377172148] },
        { id: 366, name: 'Point 13', coordinates: [42.31896871453645, 69.64069068463385] },
        { id: 367, name: 'Point 13', coordinates: [42.36419701635166, 69.66602580595979] },
        { id: 368, name: 'Point 13', coordinates: [42.34508133013827, 69.61493585310414] },
        { id: 369, name: 'Point 13', coordinates: [42.33997967460445, 69.63231707663826] },
        { id: 370, name: 'Point 13', coordinates: [42.328258403290974, 69.55073601077596] },
        { id: 371, name: 'Point 13', coordinates: [42.41464683109075, 69.58617195640487] },
        { id: 372, name: 'Point 13', coordinates: [42.31294140246954, 69.59891971869949] },
        { id: 373, name: 'Point 13', coordinates: [42.345401, 69.521782] },
        { id: 374, name: 'Point 13', coordinates: [42.330842207604654, 69.5287817138316] },
        { id: 375, name: 'Point 13', coordinates: [42.33420805600312, 69.52159262962698] },
        { id: 376, name: 'Point 13', coordinates: [42.31982327256252, 69.5167723070647] },
        { id: 377, name: 'Point 13', coordinates: [42.331290832554224, 69.56256760227019] },
        { id: 378, name: 'Point 13', coordinates: [42.288997848786124, 69.6037317597961] },
        { id: 379, name: 'Point 13', coordinates: [42.372103827922835, 69.37887425639519] },

        { id: 381, name: 'Point 13', coordinates: [42.327698, 69.532042] },
        { id: 382, name: 'Point 13', coordinates: [42.336600, 69.567712] },
        { id: 383, name: 'Point 13', coordinates: [42.354474, 69.558895] },
        { id: 384, name: 'Point 13', coordinates: [42.326716, 69.543525] },
        { id: 385, name: 'Point 13', coordinates: [42.321974, 69.544230] },
        { id: 386, name: 'Point 13', coordinates: [42.317748, 69.546381] },
        { id: 387, name: 'Point 13', coordinates: [42.317926, 69.553573] },
        { id: 388, name: 'Point 13', coordinates: [42.324910, 69.566812] },


        { id: 494, name: 'Point 13', coordinates: [42.312845, 69.581518] },
        { id: 495, name: 'Point 13', coordinates: [42.327416, 69.570370] },
        { id: 496, name: 'Point 13', coordinates: [42.328971, 69.565507] },
        { id: 497, name: 'Point 13', coordinates: [42.330600, 69.561257] },
        { id: 498, name: 'Point 13', coordinates: [42.328244, 69.552553] },
        { id: 499, name: 'Point 13', coordinates: [42.330351, 69.549793] },
        { id: 500, name: 'Point 13', coordinates: [42.330351, 69.549793] },
        { id: 501, name: 'Point 13', coordinates: [ 42.330325, 69.549746] },
        { id: 502, name: 'Point 13', coordinates: [42.331466, 69.552865] },
        { id: 503, name: 'Point 13', coordinates: [42.328246, 69.552558] },
        { id: 504, name: 'Point 13', coordinates: [42.330350, 69.549771] },
        { id: 505, name: 'Point 13', coordinates: [ 42.331464, 69.552861] },
        { id: 506, name: 'Point 13', coordinates: [42.349707, 69.535096] },
        { id: 507, name: 'Point 13', coordinates: [ 42.341906, 69.541059] },
        { id: 508, name: 'Point 13', coordinates: [42.338754, 69.546894] },
        { id: 509, name: 'Point 13', coordinates: [42.339868, 69.553289] },
        { id: 510, name: 'Point 13', coordinates: [42.342002, 69.555993] },
        { id: 511, name: 'Point 13', coordinates: [42.344865, 69.559467] },
        { id: 512, name: 'Point 13', coordinates: [42.341525, 69.566550] },
        { id: 513, name: 'Point 13', coordinates: [42.329809, 69.536040] },
        { id: 514, name: 'Point 13', coordinates: [42.334487, 69.536425] },
        { id: 515, name: 'Point 13', coordinates: [42.336429, 69.531618] },
        { id: 516, name: 'Point 13', coordinates: [42.333215, 69.523035] },
        { id: 517, name: 'Point 13', coordinates: [42.338913, 69.512135] },
        { id: 518, name: 'Point 13', coordinates: [42.340407, 69.524925] },
        { id: 519, name: 'Point 13', coordinates: [42.348559, 69.521102] },
        { id: 520, name: 'Point 13', coordinates: [42.345186, 69.534837] },
        { id: 521, name: 'Point 13', coordinates: [42.347667, 69.528055] },
        { id: 522, name: 'Point 13', coordinates: [42.376472, 69.579167] },
        { id: 523, name: 'Point 13', coordinates: [42.372556, 69.542260] },
        { id: 524, name: 'Point 13', coordinates: [42.354957, 69.529472] },
        { id: 525, name: 'Point 13', coordinates: [42.358012, 69.513077] },
        { id: 526, name: 'Point 13', coordinates: [42.368707, 69.511791] },
        { id: 527, name: 'Point 13', coordinates: [42.366416, 69.522607] },
        { id: 528, name: 'Point 13', coordinates: [42.352284, 69.534795] },
        { id: 529, name: 'Point 13', coordinates: [42.360688, 69.540035] },
        { id: 530, name: 'Point 13', coordinates: [42.362343, 69.558397] },

        { id: 531, name: 'Point 13', coordinates: [42.368264, 69.551446] },
        { id: 532, name: 'Point 13', coordinates: [42.381498, 69.547499] },
        { id: 533, name: 'Point 13', coordinates: [42.376153, 69.555738] },
        { id: 534, name: 'Point 13', coordinates: [42.371570, 69.570843] },
        { id: 535, name: 'Point 13', coordinates: [42.380738, 69.563891] },
        { id: 536, name: 'Point 13', coordinates: [42.379721, 69.586031] },
        { id: 537, name: 'Point 13', coordinates: [42.379716, 69.586035] },
        { id: 538, name: 'Point 13', coordinates: [42.375583, 69.593931] },
        { id: 539, name: 'Point 13', coordinates: [42.380662, 69.599057] },
        { id: 540, name: 'Point 13', coordinates: [42.383914, 69.598050] },
        { id: 541, name: 'Point 13', coordinates: [42.385251, 69.581228] },
        { id: 542, name: 'Point 13', coordinates: [42.388309, 69.569468] },
        { id: 543, name: 'Point 13', coordinates: [42.401731, 69.598266] },
        { id: 544, name: 'Point 13', coordinates: [42.405486, 69.606203] },
        { id: 545, name: 'Point 13', coordinates: [42.404562, 69.637578] },
        { id: 546, name: 'Point 13', coordinates: [42.408887, 69.619210] },
        { id: 547, name: 'Point 13', coordinates: [42.412705, 69.617324] },
        { id: 548, name: 'Point 13', coordinates: [42.422369, 69.568223] },
        { id: 549, name: 'Point 13', coordinates: [42.425170, 69.577495] },
        { id: 550, name: 'Point 13', coordinates: [42.410163, 69.591746] },
        { id: 551, name: 'Point 13', coordinates: [42.311252, 69.579351] },
        { id: 552, name: 'Point 13', coordinates: [42.286753, 69.606288] },
        { id: 553, name: 'Point 13', coordinates: [42.286406, 69.573199] },
        { id: 554, name: 'Point 13', coordinates: [42.296723, 69.575690] },
        { id: 555, name: 'Point 13', coordinates: [42.294849, 69.571742] },
        { id: 556, name: 'Point 13', coordinates: [42.300042, 69.566938] },
        { id: 557, name: 'Point 13', coordinates: [42.293062, 69.542602] },
        { id: 558, name: 'Point 13', coordinates: [42.291821, 69.547410] },
        { id: 559, name: 'Point 13', coordinates: [42.293256, 69.549343] },
        { id: 560, name: 'Point 13', coordinates: [42.297907, 69.557795] },
        { id: 561, name: 'Point 13', coordinates: [42.295040, 69.554361] },
        { id: 562, name: 'Point 13', coordinates: [42.288030, 69.553763] },
        { id: 563, name: 'Point 13', coordinates: [42.291469, 69.555008] },
        { id: 564, name: 'Point 13', coordinates: [42.291695, 69.560499] },
        { id: 565, name: 'Point 13', coordinates: [42.286662, 69.561144] },
        { id: 566, name: 'Point 13', coordinates: [42.305330, 69.616946] },
        { id: 567, name: 'Point 13', coordinates: [42.304503, 69.616430] },
        { id: 568, name: 'Point 13', coordinates: [42.308864, 69.612014] },
        { id: 569, name: 'Point 13', coordinates: [42.300074, 69.544063] },

        { id: 570, name: 'Point 13', coordinates: [42.307591, 69.584204] },
        { id: 571, name: 'Point 13', coordinates: [42.310490, 69.585741] },
        { id: 572, name: 'Point 13', coordinates: [42.304723, 69.591062] },
        { id: 573, name: 'Point 13', coordinates: [42.303035, 69.594243] },
        { id: 574, name: 'Point 13', coordinates: [42.302844, 69.598706] },
        { id: 575, name: 'Point 13', coordinates: [42.346309, 69.663209] },
        { id: 576, name: 'Point 13', coordinates: [42.342944, 69.664242] },
        { id: 577, name: 'Point 13', coordinates: [42.339052, 69.657628] },
        { id: 578, name: 'Point 13', coordinates: [42.338865, 69.651874] },
        { id: 579, name: 'Point 13', coordinates: [42.351663, 69.572143] },
        { id: 580, name: 'Point 13', coordinates: [42.353258, 69.577896] },
        { id: 581, name: 'Point 13', coordinates: [42.344090, 69.579867] },
        { id: 582, name: 'Point 13', coordinates: [42.342240, 69.578499] },
        { id: 583, name: 'Point 13', coordinates: [42.341663, 69.585184] },
        { id: 584, name: 'Point 13', coordinates: [42.336961, 69.579701] },
        { id: 585, name: 'Point 13', coordinates: [42.334599, 69.579435] },
        { id: 580, name: 'Point 13', coordinates: [42.331667, 69.581755] },
        { id: 591, name: 'Point 13', coordinates: [42.330523, 69.578233] },
        { id: 592, name: 'Point 13', coordinates: [42.330204, 69.592997] },
        { id: 593, name: 'Point 13', coordinates: [42.329791, 69.598919] },
        { id: 594, name: 'Point 13', coordinates: [42.327082, 69.597545] },
        { id: 595, name: 'Point 13', coordinates: [42.325427, 69.597715] },
        { id: 596, name: 'Point 13', coordinates: [42.325427, 69.597715] },
        { id: 597, name: 'Point 13', coordinates: [42.323896, 69.600723] },
        { id: 598, name: 'Point 13', coordinates: [42.322941, 69.606216] },
        { id: 599, name: 'Point 13', coordinates: [42.330841, 69.605272] },
        { id: 600, name: 'Point 13', coordinates: [42.328740, 69.607034] },
        { id: 601, name: 'Point 13', coordinates: [42.328199, 69.612657] },
        { id: 602, name: 'Point 13', coordinates: [42.329407, 69.617636] },
        { id: 603, name: 'Point 13', coordinates: [42.325778, 69.617373] },
        { id: 604, name: 'Point 13', coordinates: [42.322880, 69.618533] },
        { id: 605, name: 'Point 13', coordinates: [42.328038, 69.644150] },
        { id: 606, name: 'Point 13', coordinates: [42.326320, 69.645484] },
        { id: 607, name: 'Point 13', coordinates: [42.332467, 69.640506] },
        { id: 608, name: 'Point 13', coordinates: [42.331445, 69.638274] },
        { id: 609, name: 'Point 13', coordinates: [42.325077, 69.636602] },
        { id: 610, name: 'Point 13', coordinates: [42.325077, 69.636602] },
        { id: 611, name: 'Point 13', coordinates: [42.325077, 69.636602] },
        { id: 612, name: 'Point 13', coordinates: [42.323485, 69.641019] },
        { id: 613, name: 'Point 13', coordinates: [42.321479, 69.635487] },
        { id: 614, name: 'Point 13', coordinates: [42.320589, 69.636944] },
        { id: 615, name: 'Point 13', coordinates: [42.336251, 69.640675] },
        { id: 616, name: 'Point 13', coordinates: [42.335840, 69.637460] },
        { id: 617, name: 'Point 13', coordinates: [42.338197, 69.637974] },
        { id: 618, name: 'Point 13', coordinates: [42.315396, 69.664924] },
        { id: 619, name: 'Point 13', coordinates: [42.313487, 69.662780] },
        { id: 620, name: 'Point 13', coordinates: [42.318295, 69.652179] },
        { id: 621, name: 'Point 13', coordinates: [42.317402, 69.650804] },
        { id: 622, name: 'Point 13', coordinates: [42.310807, 69.654193] },
        { id: 623, name: 'Point 13', coordinates: [42.311955, 69.645953] },
        { id: 624, name: 'Point 13', coordinates: [42.306923, 69.642438] },
        { id: 625, name: 'Point 13', coordinates: [42.305647, 69.651276] },
        { id: 626, name: 'Point 13', coordinates: [42.303802, 69.646472] },
        { id: 627, name: 'Point 13', coordinates: [42.304755, 69.638746] },
        { id: 628, name: 'Point 13', coordinates: [42.299277, 69.634625] },
        { id: 629, name: 'Point 13', coordinates: [42.295328, 69.640637] },
        { id: 630, name: 'Point 13', coordinates: [42.297620, 69.622953] },
        { id: 631, name: 'Point 13', coordinates: [42.294944, 69.614968] },
        { id: 632, name: 'Point 13', coordinates: [42.297619, 69.606300] },
        { id: 633, name: 'Point 13', coordinates: [42.299720, 69.594797] },
        { id: 634, name: 'Point 13', coordinates: [42.295199, 69.591023] },
        { id: 635, name: 'Point 13', coordinates: [42.300105, 69.585790] },
        { id: 636, name: 'Point 13', coordinates: [42.293925, 69.579005] },
        { id: 637, name: 'Point 13', coordinates: [42.300295, 69.577205] },
        { id: 638, name: 'Point 13', coordinates: [42.301889, 69.571024] },
        { id: 639, name: 'Point 13', coordinates: [42.304630, 69.574027] },
        { id: 640, name: 'Point 13', coordinates: [42.306478, 69.572915] },
        { id: 641, name: 'Point 13', coordinates: [42.307301, 69.577463] },
        { id: 642, name: 'Point 13', coordinates: [42.309503, 69.578622] },
        { id: 643, name: 'Point 13', coordinates: [42.305905, 69.603340] },

        { id: 644, name: 'Point 13', coordinates: [42.361593, 69.687762] },
        { id: 645, name: 'Point 13', coordinates: [42.387609, 69.626299] },
        { id: 646, name: 'Point 13', coordinates: [42.374238, 69.645254] },
        { id: 647, name: 'Point 13', coordinates: [42.379812, 69.641987] },
        { id: 648, name: 'Point 13', coordinates: [42.384356, 69.638675] },
        { id: 649, name: 'Point 13', coordinates: [42.377233, 69.656745] },
        { id: 650, name: 'Point 13', coordinates: [42.382204, 69.662072] },
        { id: 651, name: 'Point 13', coordinates: [42.382741, 69.653150] },
        { id: 652, name: 'Point 13', coordinates: [42.378442, 69.676438] },
        { id: 653, name: 'Point 13', coordinates: [42.373639, 69.633020] },
        { id: 654, name: 'Point 13', coordinates: [42.369341, 69.645799] },
        { id: 655, name: 'Point 13', coordinates: [42.371479, 69.652885] },
        { id: 656, name: 'Point 13', coordinates: [42.371161, 69.658757] },
        { id: 657, name: 'Point 13', coordinates: [42.373444, 69.660534] },
        { id: 658, name: 'Point 13', coordinates: [42.369433, 69.677302] },
        { id: 659, name: 'Point 13', coordinates: [42.366256, 69.666010] },
        { id: 660, name: 'Point 13', coordinates: [42.362865, 69.648258] },
        { id: 661, name: 'Point 13', coordinates: [42.355389, 69.662308] },
        { id: 662, name: 'Point 13', coordinates: [42.378016, 69.617204] },
        { id: 663, name: 'Point 13', coordinates: [42.376994, 69.601923] },
        { id: 664, name: 'Point 13', coordinates: [42.361981, 69.600629] },
        { id: 665, name: 'Point 13', coordinates: [42.364718, 69.606215] },
        { id: 666, name: 'Point 13', coordinates: [42.358918, 69.605094] },
        { id: 667, name: 'Point 13', coordinates: [42.357649, 69.605879] },
        { id: 668, name: 'Point 13', coordinates: [42.357138, 69.616601] },
        { id: 669, name: 'Point 13', coordinates: [42.354016, 69.612993] },
        { id: 670, name: 'Point 13', coordinates: [42.354908, 69.626984] },
        { id: 671, name: 'Point 13', coordinates: [42.366304, 69.626729] },
        { id: 672, name: 'Point 13', coordinates: [42.363947, 69.624585] },
        { id: 673, name: 'Point 13', coordinates: [42.363245, 69.632310] },
        { id: 674, name: 'Point 13', coordinates: [42.359556, 69.623989] },
        { id: 675, name: 'Point 13', coordinates: [42.357770, 69.629134] },
        { id: 676, name: 'Point 13', coordinates: [42.355290, 69.637195] },
        { id: 677, name: 'Point 13', coordinates: [42.359367, 69.642953] },
        { id: 678, name: 'Point 13', coordinates: [42.354720, 69.652952] },
        { id: 679, name: 'Point 13', coordinates: [42.359463, 69.636189] },
        { id: 680, name: 'Point 13', coordinates: [42.351472, 69.651096] },
        { id: 681, name: 'Point 13', coordinates: [42.347114, 69.608253] },
        { id: 682, name: 'Point 13', coordinates: [42.344058, 69.609710] },
        { id: 683, name: 'Point 13', coordinates: [42.346595, 69.611775] },
        { id: 684, name: 'Point 13', coordinates: [42.344022, 69.612891] },
        { id: 685, name: 'Point 13', coordinates: [42.345620, 69.612159] },
        { id: 686, name: 'Point 13', coordinates: [42.346185, 69.614555] },
        { id: 687, name: 'Point 13', coordinates: [42.345236, 69.615292] },
        { id: 688, name: 'Point 13', coordinates: [42.345200, 69.607526] },
        { id: 689, name: 'Point 13', coordinates: [42.343414, 69.624950] },
        { id: 690, name: 'Point 13', coordinates: [42.342751, 69.626833] },
        { id: 691, name: 'Point 13', coordinates: [42.342402, 69.628767] },
        { id: 692, name: 'Point 13', coordinates: [42.342843, 69.631905] },
        { id: 693, name: 'Point 13', coordinates: [42.345777, 69.627126] },
        { id: 694, name: 'Point 13', coordinates: [42.344804, 69.636172] },
        { id: 695, name: 'Point 13', coordinates: [42.342063, 69.640030] },
        { id: 696, name: 'Point 13', coordinates: [42.341875, 69.644066] },
        { id: 697, name: 'Point 13', coordinates: [42.342956, 69.648100] },
        { id: 698, name: 'Point 13', coordinates: [42.342367, 69.677555] },
        { id: 699, name: 'Point 13', coordinates: [42.351217, 69.677535] },
        { id: 700, name: 'Point 13', coordinates: [42.345298, 69.685010] },
        { id: 701, name: 'Point 13', coordinates: [42.351597, 69.696766] },
        { id: 702, name: 'Point 13', coordinates: [42.353123, 69.699513] },
        { id: 703, name: 'Point 13', coordinates: [42.356565, 69.713761] },
        { id: 704, name: 'Point 13', coordinates: [42.362734, 69.704322] },
        { id: 705, name: 'Point 13', coordinates: [42.369029, 69.709295] },
        { id: 706, name: 'Point 13', coordinates: [42.375845, 69.701318] },
        { id: 707, name: 'Point 13', coordinates: [42.359819, 69.693419] },
        

 

        // Другие точки оператора 2...

        // Қар-Тел БС *******************************************************************************************************
      ],
       // Замените на путь к иконке маркера оператора 2
      pointIcon: icon2, // Замените на путь к иконке точки оператора 2
    },
    {
        id: 3,
        name: 'Қар-Тел БС',
        points: [
          { id: 223, name: 'Point 13', coordinates: [42.435932, 69.637777] },
          { id: 224, name: 'Point 13', coordinates: [42.288802, 69.782876] },
          { id: 225, name: 'Point 13', coordinates: [42.288802, 69.781576] },
          { id: 226, name: 'Point 13', coordinates: [42.433862, 69.637228] },
          { id: 227, name: 'Point 13', coordinates: [42.429240, 69.644233] },
          { id: 228, name: 'Point 13', coordinates: [42.425024, 69.637228] },
          { id: 229, name: 'Point 13', coordinates: [42.430132, 69.625189] },
          { id: 230, name: 'Point 13', coordinates: [42.425916, 69.613259] },
          { id: 231, name: 'Point 13', coordinates: [42.422315, 69.579314] },
          { id: 232, name: 'Point 13', coordinates: [42.416720, 69.596388] },
          { id: 233, name: 'Point 13', coordinates: [42.405947, 69.657744] },
          { id: 234, name: 'Point 13', coordinates: [42.403538, 69.644897] },
          { id: 235, name: 'Point 13', coordinates: [42.397449, 69.648432] },
          { id: 236, name: 'Point 13', coordinates: [42.402387, 69.551133] },
          { id: 237, name: 'Point 13', coordinates: [42.402524, 69.581320] },
          { id: 238, name: 'Point 13', coordinates: [42.398840, 69.577455] },
          { id: 239, name: 'Point 13', coordinates: [42.384705, 69.493224] },
          { id: 240, name: 'Point 13', coordinates: [42.365013, 69.667002] },
          { id: 241, name: 'Point 13', coordinates: [42.382343, 69.714428] },
          { id: 242, name: 'Point 13', coordinates: [42.377539, 69.759573] },
          { id: 243, name: 'Point 13', coordinates: [42.359977, 69.617849] },
          { id: 244, name: 'Point 13', coordinates: [42.353970, 69.636893] },
          { id: 245, name: 'Point 13', coordinates: [42.346964, 69.605129] },
          { id: 246, name: 'Point 13', coordinates: [42.333442, 69.599562] },
          { id: 247, name: 'Point 13', coordinates: [42.329917, 69.624097] },
          { id: 248, name: 'Point 13', coordinates: [42.328554, 69.585776] },
          { id: 249, name: 'Point 13', coordinates: [42.310811, 69.618486] },
          { id: 250, name: 'Point 13', coordinates: [42.308049, 69.612302] },
          { id: 251, name: 'Point 13', coordinates: [42.292958, 69.656463] },
          { id: 252, name: 'Point 13', coordinates: [42.281700, 69.665582] },
          { id: 253, name: 'Point 13', coordinates: [42.280907, 69.633370] },
          { id: 254, name: 'Point 13', coordinates: [42.270245, 69.590482] },
          { id: 255, name: 'Point 13', coordinates: [42.284160, 69.540934] },
          { id: 256, name: 'Point 13', coordinates: [42.234006, 69.638896] },
          { id: 481, name: 'Point 13', coordinates: [42.309028, 69.749883] },
          { id: 482, name: 'Point 13', coordinates: [42.379100, 69.713957] },
          { id: 483, name: 'Point 13', coordinates: [42.415810, 69.682631] },
          { id: 484, name: 'Point 13', coordinates: [42.349859, 69.469469] },
          { id: 485, name: 'Point 13', coordinates: [42.350689, 69.470657] },
          { id: 486, name: 'Point 13', coordinates: [42.358587, 69.451046] },
          { id: 487, name: 'Point 13', coordinates: [42.229564, 69.498393] },
          { id: 488, name: 'Point 13', coordinates: [42.230816, 69.637558] },
          { id: 489, name: 'Point 13', coordinates: [42.258239, 69.625842] },
          { id: 490, name: 'Point 13', coordinates: [42.375875, 69.759438] },
          { id: 491, name: 'Point 13', coordinates: [42.428186, 69.625308] },
          { id: 492, name: 'Point 13', coordinates: [42.424172, 69.613307] },
          { id: 493, name: 'Point 13', coordinates: [42.420625, 69.578824] },
          
          // Другие точки оператора 1...
        ],
        pointIcon: icon3 // Замените на путь к иконке точки оператора 1
      },
    // Другие операторы...

    //AMC Kcell*********************************************************************************************************************************

    ///////////// AMC Желтый //////////////////////////////////////////////////////
    {
      id: 4,
      name: 'Kcell AMC Желтый',
      points: [
       
        { id: 708, name: 'Point 13', coordinates: [42.341472, 69.654167] }, //Мирас


        // Другие точки оператора 1...
      ],
      pointIcon: iconYellow // Замените на путь к иконке точки оператора 1
    },
/////////////////////// AMC Красный /////////////////////////////////////////////////
    {
      id: 5,
      name: 'Kcell AMC Красный',
      points: [
        { id: 710, name: 'Point 13', coordinates: [42.3330560,69.6607550] }, //Пахтакор
      ],
      pointIcon: iconRed
    },

////////////////////// AMC Зеленый //////////////////////////////////////////////////
    {
      id: 6,
      name: 'Kcell AMC Зеленый',
      points: [
        { id: 712, name: 'Point 13', coordinates: [42.2901460,69.5135650], photoUrl: kursay, description: 'Құрсай'}, //Құрсай
        { id: 714, name: 'Point 13', coordinates: [42.3098520,69.5142440], photoUrl: kursayagro, description: 'Құрсай (Агроферма)'}, //Құрсай (Агроферма)
        { id: 7112, name: 'Point 13', coordinates: [42.2731570,69.5742230], photoUrl: saule, description: 'Сауле' }, //Сауле },
        { id: 7155, name: 'Point 13', coordinates: [42.2895190,69.6741040] }, //Орманшы
        { id: 7166, name: 'Point 13', coordinates: [42.4506030,69.5217880], photoUrl: kainarbulakss, description: 'Қайнарбұлақ саяжай' }, //Қайнарбұлақ (саяжай)
        { id: 7177, name: 'Point 13', coordinates: [42.3781940,69.5086040] }, //Ынтымақ-1
        { id: 7188, name: 'Point 13', coordinates: [42.2750730,69.6277280] }, //Оңтүстік ш/а
        { id: 7199, name: 'Point 13', coordinates: [42.256733, 69.576154] }, //276 орам
        { id: 71110, name: 'Point 13', coordinates: [42.4361570,69.5545610], photoUrl: kainarbulaks, description: 'Қайнарбұлақ саяжай' }, //Қайнарбұлақ (саяжай )
        { id: 7111, name: 'Point 13', coordinates: [42.4323740,69.5296830], photoUrl: kainarbulak, description: 'Қайнарбұлақ саяжай'}, //Қайнарбұлақ (саяжай )
        { id: 7113, name: 'Point 13', coordinates: [42.3828338,69.5315898] }, //Нұршуақ
        { id: 7114, name: 'Point 13', coordinates: [42.4226585334173, 69.64093658958653] }, //Бозарық (саяжай аумағы)
        { id: 7115, name: 'Point 13', coordinates: [42.26267930583644, 69.57906536813691] }, //Береке ш/а
        { id: 7116, name: 'Point 13', coordinates: [42.4604820,69.5496560] }, //Қайнарбұлақ (жаңақұрылыс)
        { id: 7117, name: 'Point 13', coordinates: [42.4616450,69.5145330] }, //Қайнарбұлақ (жаңақұрылыс)
        { id: 7118, name: 'Point 13', coordinates: [42.3494530,69.4639920] }, //Игілік
        { id: 7119, name: 'Point 13', coordinates: [42.4035980,69.6471710] }, //Тұран ш/а
        { id: 7120, name: 'Point 13', coordinates: [42.4107120,69.5321590] }, //Нұршуақ (жаңақұрылыс)
        { id: 7121, name: 'Point 13', coordinates: [42.3369810,69.6743170] }, //Пахтакор
        { id: 7122, name: 'Point 13', coordinates: [42.3512260,69.4285320] }, //Жаңаталап
        { id: 7123, name: 'Point 13', coordinates: [42.2132600,69.5274530] }, //Ақтас
        { id: 7125, name: 'Point 13', coordinates: [42.3544150,69.6039180] }, //Наурыз ш/а
        { id: 7126, name: 'Point 13', coordinates: [42.420928, 69.641500] }, //Бозарық жаңақұрылыс
        { id: 7127, name: 'Point 13', coordinates: [42.4103850,69.5826300] }, //Асар-2
        { id: 7128, name: 'Point 13', coordinates: [42.3946196,69.6119593] }, //Асар ш/а
        { id: 7129, name: 'Point 13', coordinates: [42.3796383,69.7637596] }, //Мартөбе
        { id: 7130, name: 'Point 13', coordinates: [42.254683, 69.722178] }, //Бадам
        { id: 7131, name: 'Point 13', coordinates: [42.368993, 69.530215] }, //Ақниет
        { id: 7132, name: 'Point 13', coordinates: [42.399833, 69.655556] }, //Тұран
        { id: 748, name: 'Point 13', coordinates: [42.319250, 69.681778] }, //Азат
        { id: 749, name: 'Point 13', coordinates: [42.382306, 69.503083] }, //Ынтымақ-2 (Тұрлан экспедициясы)
        { id: 751, name: 'Point 13', coordinates: [42.340278, 69.494694] }, //Игілік
        { id: 752, name: 'Point 13', coordinates: [42.415152, 69.549717] }, //Достық (автоцон)
        { id: 753, name: 'Point 13', coordinates: [42.231949, 69.506200] }, //Ақжар
        { id: 754, name: 'Point 13', coordinates: [42.193417, 69.519806] }, //Ақтас
        { id: 709, name: 'Point 13', coordinates: [42.387972, 69.591583] }, //Ақжайық
          
      ],
      pointIcon: iconGreen
    },
      /////////// Wi-Fi////////////////////////////
    {
      id: 7,
      name: 'Қоғамдық Wi-Fi',
      points: [
        { id: 259, name: 'Point 13', coordinates: [42.368467, 69.622452] },
        { id: 260, name: 'Point 13', coordinates: [42.368498, 69.619611] },
        { id: 261, name: 'Point 13', coordinates: [42.369184, 69.618229] },
        { id: 262, name: 'Point 13', coordinates: [42.369756, 69.616690] },
        { id: 263, name: 'Point 13', coordinates: [42.370086, 69.616141] },
        { id: 264, name: 'Point 13', coordinates: [42.368187, 69.621332] },
        { id: 265, name: 'Point 13', coordinates: [42.387555, 69.627446] },
        { id: 266, name: 'Point 13', coordinates: [42.387251, 69.627129] },
        { id: 267, name: 'Point 13', coordinates: [42.384895, 69.627532] },
        { id: 268, name: 'Point 13', coordinates: [42.384684, 69.626292] },
        { id: 269, name: 'Point 13', coordinates: [42.384802, 69.625831] },
        { id: 270, name: 'Point 13', coordinates: [42.385398, 69.627590] },
        { id: 271, name: 'Point 13', coordinates: [42.363681, 69.621673] },
        { id: 272, name: 'Point 13', coordinates: [42.364453, 69.622247] },
        { id: 273, name: 'Point 13', coordinates: [42.382624, 69.626706] },
        { id: 274, name: 'Point 13', coordinates: [42.382572, 69.628291] },
        { id: 275, name: 'Point 13', coordinates: [42.353050, 69.653833] },
        { id: 276, name: 'Point 13', coordinates: [42.353384, 69.652796] },
        { id: 277, name: 'Point 13', coordinates: [42.313208, 69.603350] },
        { id: 278, name: 'Point 13', coordinates: [42.312343, 69.603675] },
        { id: 279, name: 'Point 13', coordinates: [42.316115, 69.586907] },
        { id: 280, name: 'Point 13', coordinates: [42.316713, 69.586068] },
        { id: 281, name: 'Point 13', coordinates: [42.317262, 69.585321] },
        { id: 282, name: 'Point 13', coordinates: [42.317893, 69.584862] },
        { id: 283, name: 'Point 13', coordinates: [42.3562, 69.642939] },
        { id: 284, name: 'Point 13', coordinates: [42.356387, 69.642303] },
        { id: 285, name: 'Point 13', coordinates: [42.356658, 69.641436] },
        { id: 286, name: 'Point 13', coordinates: [42.356806, 69.640922] },
        { id: 287, name: 'Point 13', coordinates: [42.320967, 69.588396] },
        { id: 288, name: 'Point 13', coordinates: [42.320777, 69.588989] },
        { id: 289, name: 'Point 13', coordinates: [42.316966, 69.609838] },
        { id: 290, name: 'Point 13', coordinates: [42.316526, 69.611778] },
        { id: 291, name: 'Point 13', coordinates: [42.315614, 69.615520] },
        { id: 292, name: 'Point 13', coordinates: [42.316329, 69.612322] },
        { id: 293, name: 'Point 13', coordinates: [42.410001, 69.598731] },
        { id: 294, name: 'Point 13', coordinates: [42.410488, 69.598412] },
        { id: 295, name: 'Point 13', coordinates: [42.321241, 69.579156] },
        { id: 296, name: 'Point 13', coordinates: [42.320844, 69.580382] },
        { id: 297, name: 'Point 13', coordinates: [42.320519, 69.581269] },
        { id: 298, name: 'Point 13', coordinates: [42.321406, 69.588006] },
        { id: 299, name: 'Point 13', coordinates: [42.321930, 69.587762] },
        { id: 750, name: 'Point 13', coordinates: [42.335950, 69.599572] },
        { id: 751, name: 'Point 13', coordinates: [42.336132, 69.598670] },
        { id: 752, name: 'Point 13', coordinates: [42.362386, 69.622876] },
        { id: 753, name: 'Point 13', coordinates: [42.361229, 69.625968] },
        { id: 754, name: 'Point 13', coordinates: [42.361797, 69.624408] },
        { id: 755, name: 'Point 13', coordinates: [42.360961, 69.62648] },
        { id: 756, name: 'Point 13', coordinates: [42.366985, 69.49358] },
        { id: 757, name: 'Point 13', coordinates: [42.366813, 69.494189] },
        { id: 758, name: 'Point 13', coordinates: [42.337293, 69.593262] },
        { id: 759, name: 'Point 13', coordinates: [42.316136, 69.599611] },
        { id: 760, name: 'Point 13', coordinates: [42.315859, 69.598797] },
        { id: 761, name: 'Point 13', coordinates: [42.316094, 69.600755] },

        { id: 400, name: 'Point 13', coordinates: [42.329561, 69.590125] },//Абай саябағы
        { id: 401, name: 'Point 13', coordinates: [42.329387, 69.591047] },//Абай саябағы
        { id: 402, name: 'Point 13', coordinates: [42.329835, 69.589089] },//Абай саябағы
        { id: 403, name: 'Point 13', coordinates: [42.330037, 69.588215] },//Абай саябағы
        { id: 404, name: 'Point 13', coordinates: [42.330140, 69.587480] },//Абай саябағы
        { id: 405, name: 'Point 13', coordinates: [42.307591, 69.598563] },//Тәуелсіздік саябағы
        { id: 406, name: 'Point 13', coordinates: [42.307456, 69.599148] },//Тәуелсіздік саябағы
        { id: 407, name: 'Point 13', coordinates: [42.307274, 69.599947] },//Тәуелсіздік саябағы
        { id: 408, name: 'Point 13', coordinates: [42.307032, 69.599314] },//Тәуелсіздік саябағы
        { id: 409, name: 'Point 13', coordinates: [42.309806, 69.630092] },//Жеңіс саябағы
        { id: 410, name: 'Point 13', coordinates: [42.309640, 69.630639] },//Жеңіс саябағы
        { id: 411, name: 'Point 13', coordinates: [42.310060, 69.630065] },//Жеңіс саябағы
        { id: 412, name: 'Point 13', coordinates: [42.314836, 69.564995] },//Металлург саябағы
        { id: 413, name: 'Point 13', coordinates: [42.314067, 69.566132] },//Металлург саябағы
        { id: 414, name: 'Point 13', coordinates: [42.298945, 69.610528] },//Жд вокзал 
        { id: 415, name: 'Point 13', coordinates: [42.298626, 69.610037] },//Жд вокзал 
        { id: 416, name: 'Point 13', coordinates: [42.302446, 69.610086] },//Қошқар ата аумағы

      
      ],
      pointIcon: icon5
    },

    ////////////////////// Beeline ///////////////////////////////////////////////////////////////
    /////////////////////// AMC Красный /////////////////////////////////////////////////
    {
      id: 8,
      name: 'Beeline AMC Красный',
      points: [
        { id: 764, name: 'Point 13', coordinates: [42.387604, 69.626858  ] }, //Памятник Байдыбек Би
        { id: 777, name: 'Point 13', coordinates: [42.390550, 69.645832 ] }, //Тұран
        { id: 778, name: 'Point 13', coordinates: [42.366565, 69.416148  ] }, //Тәуелсіздікке 20 жыл
        { id: 779, name: 'Point 13', coordinates: [42.418110, 69.634653  ] }, //Бозарык



      ],
      pointIcon: iconRedBee
    },
    ///////////// AMC Желтый //////////////////////////////////////////////////////
    {
      id: 9,
      name: 'Beeline AMC Желтый',
      points: [
        { id: 762, name: 'Point 13', coordinates: [42.28702, 69.769859   ], photoUrl: bazarqaqpa, description: 'Базарқақпа' }, //Базарқақпа
        { id: 763, name: 'Point 13', coordinates: [42.265494, 69.838480 ], photoUrl: abdullabad, description: 'Абдуллабад'  },  //Абдуллабад
        { id: 765, name: 'Point 13', coordinates: [42.361747, 69.530982 ], photoUrl: aqniet, description: 'Ақниет' },  //Акниет
        { id: 767, name: 'Point 13', coordinates: [42.330480, 69.655061 ], photoUrl: cabisko, description: 'Кабиско' }, //Кабиско
        { id: 768, name: 'Point 13', coordinates: [42.428374, 69.557370], photoUrl: qainarbulak, description: 'Қайнарбулақ'},  //Дача Кайнарбулак
        { id: 769, name: 'Point 13', coordinates: [42.312419, 69.784546 ], photoUrl: sayram, description: 'Сайрам' }, //Сайрам
        { id: 770, name: 'Point 13', coordinates: [42.256582, 69.575719 ], photoUrl: oram276, description: '276-квартал' }, //276-квартал
        { id: 771, name: 'Point 13', coordinates: [42.400713, 69.592538 ], photoUrl: asar, description: 'Асар'  }, //Асар
        { id: 772, name: 'Point 13', coordinates: [42.429708, 69.610698 ], photoUrl: bozaryq, description: 'Бозарык'  }, //Бозарык
        { id: 773, name: 'Point 13', coordinates: [42.385817, 69.664309  ], photoUrl: turan, description: 'Туран'  }, //Туран
        { id: 774, name: 'Point 13', coordinates: [42.394340, 69.610342  ], photoUrl: asarkoilyk, description: 'Асар'  }, //Асар
        { id: 775, name: 'Point 13', coordinates: [42.342562, 69.648326  ], photoUrl: shapagat, description: 'Шапағат'  }, //Шапағат
        { id: 778, name: 'Point 13', coordinates: [42.366565, 69.416148  ], photoUrl: tauelsizdik, description: 'Тәуелсіздікке 20 жыл'  }, //Тәуелсіздікке 20 жыл
        { id: 777, name: 'Point 13', coordinates: [42.390550, 69.645832 ], photoUrl: turan2, description: 'Тұран'  }, //Тұран
      
      ],
      pointIcon: iconYellowBee
    },
    ////////////////////// AMC Зеленый //////////////////////////////////////////////////
    {
      id: 10,
      name: 'Beeline AMC Зеленый',
      points: [
      
      
      ],
      pointIcon: iconGreenBee
    },

    ////////////////////////////////////////// ОДН ///////////////////////////////////////////////
    {
      id: 11,
      name: 'ОДН',
      points: [
        { id: 780, name: 'Point 13', coordinates: [42.411851, 69.560508 ], photoUrl: OdnDostyq, description: 'Достық' },
        { id: 781, name: 'Point 13', coordinates: [42.457264, 69.514435 ], photoUrl: OdnQainarbulaq, description: 'Қайнарбұлақ' },
        { id: 782, name: 'Point 13', coordinates: [42.415537, 69.607338 ], photoUrl: OdnAsar, description: 'Асар 2' },
        { id: 783, name: 'Point 13', coordinates: [42.428733, 69.610854 ], photoUrl: OdnBozaryq, description: 'Бозарық' },
        { id: 784, name: 'Point 13', coordinates: [42.362662, 69.430694 ], photoUrl: OdnZhanatalap, description: 'Жаңаталап' },
      ],
      pointIcon: odn
    }
  // Другие операторы...
  ];